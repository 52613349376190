// 홈
#home {
  .swiper-wrap {
    .swiper-box {
      width: 100%;
      .swiper-slide {
        background-color: var(--bs-gray-200);
        .img-box {
          width: 100%;
          height: 100%;
          .grid-label {
            z-index: 100;
            position: absolute;
            bottom: 7px;
            right: 10px;
            height: 40px;
            border: 2px solid #203455;
            border-radius: 8px;
            height: 40px;
            .sale-text {
              background-color: #203455;
              color: #ffffff;
              text-align: center;
              font-size: 0.5rem;
              height: 100%;
              float: right;
              padding: 4px 0.5rem 0;
              line-height: 1.3;
            }
            .sale-won {
              background-color: #ffffff;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              text-align: center;
              font-size: 1rem;
              height: 100%;
              font-weight: bold;
              float: left;
              padding: 0 0.5rem;
              line-height: 36px;
              color: #203455;
              span {
                font-size: 0.75rem;
                font-weight: 500;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
    &.ports {
      // padding: 1rem 0;
      margin: 1rem 0;
      .swiper-box {
        // padding: 0 1rem;
        margin: 0 1rem;
      }
    }
  }
  .btn-connect-wifi {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
// 홈 - 통광고
#modal-fullpage-prm {
  // height: calc(100% - 80px);
  .modal-body {
    position: relative;
    .btn-area {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem;
      .btn {
        flex: 1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .btn-sur {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
      position: absolute;
      top: 55px;
      right: 15px;
    }
  }
}
// 홈 - splash screen
#modal-info-prm {
  // height: calc(100% - 80px);
  .modal-body {
    position: relative;
    .btn-area {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem;
      .btn {
        flex: 1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .btn-sur {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
      position: absolute;
      top: 55px;
      right: 15px;
    }
  }
}
//설문조사
#survey {
  padding: 1rem;
  background-color: #d5e6f4;
  padding-top: 80px;
  overflow: auto;
  .border-line {
    border-bottom: 10px solid #e8e8e8;
    margin-bottom: 1rem;
  }
  article {
    padding: 1rem;
    border-radius: 1rem;
    background: var(--bs-white);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  .flex-form {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
    .form-label {
      margin: 0;
      color: #4e4e4e;
      font-size: 1rem;
    }
  }
  header {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 1024px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 1rem;
    background-color: #203455;
    z-index: 10;
    h3 {
      margin: 0;
      font-size: 1.25rem;
      color: var(--bs-white);
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
      width: inherit;
      width: 80%;
      text-align: center;
    }
  }
  .btn-radius {
    border-radius: 0.5rem;
  }
}
// 프로모션 상세
#prm-detail {
  padding-top: 50px;
  overflow: hidden;
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 1rem;
    background: var(--bs-white);
    z-index: 10;
    h3 {
      margin: 0;
      font-size: 1.25rem;
      color: var(--bs-gray);
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
      width: inherit;
      width: 80%;
      text-align: center;
    }
  }
  .swiper-box {
    margin: 0 1rem;
    .swiper-slide {
      border: 1px solid #bebebe;
    }
  }
  .info {
    padding: 0.75rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    max-height: 100%;
    overflow: auto;
    .title {
      padding: 0 1rem;
      .flex-between {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid var(--bs-dark);
      }
      h6 {
        color: var(--bs-gray);
        font-weight: 800;
        margin: 0;
      }
      .badge {
        padding: 0.25rem 2rem;
        border: 1px solid var(--bs-secondary);
        background: var(--bs-primary-light) !important;
        color: var(--bs-dark);
        font-size: 1rem;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      }
    }
    .dl-box {
      padding: 0 1rem 1rem 1rem;
      dl {
        margin: 0;
        padding: 0.4rem 0;
        border-top: 1px solid var(--bs-border);
        &:first-of-type {
          border-top: 0;
        }
        dt {
          flex: 0 0 24px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          i {
            margin-left: -0.1rem;
          }
        }
      }
    }
  }
  .coupon-section {
    padding: 2rem 1rem;
    background-color: #f9f9f9;
    .coupon-text {
      font-size: 1rem;
      color: #6f6f6f;
      text-align: center;
      line-height: 1.6;
    }
    .coupon-box {
      width: 100%;
      height: 128px;
      border: 1px solid #e0e0e0;
      background-color: #ffffff;
      border-radius: 12px;
      padding: 1rem 1.5rem 1rem 1rem;
      position: relative;
      margin-bottom: 1rem;
      .won-top {
        background-color: #f9f9f9;
        width: 42px;
        height: 20px;
        border: 1px solid #e0e0e0;
        position: absolute;
        top: -1px;
        right: 66px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top: 0;
        z-index: 10;
      }
      .won-bot {
        background-color: #f9f9f9;
        width: 42px;
        height: 20px;
        border: 1px solid #e0e0e0;
        position: absolute;
        bottom: -1px;
        right: 66px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom: 0;
        z-index: 10;
      }
      .coupon-title {
        p {
          font-size: 1rem;
        }
      }
      .grid-down {
        display: grid;
        grid-template-columns: 1fr 80px;
        align-items: center;
        margin-bottom: 0.5rem;
        .right-icons {
          justify-self: end;
        }
        p {
          font-size: 2.25rem;
          font-weight: bold;
        }
      }
      .flex-day {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          color: #6f6f6f;
          font-size: 1rem;
        }
      }
    }
  }
}

// 플레이스
#place {
  position: relative;
  overflow: hidden;
  header {
    // position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: rgba(34, 34, 34, 0.7);
    box-shadow: 0px 0.5px 4px rgba(104, 104, 104, 0.25);
    padding: 0.75rem 1rem;
    .input-group {
      border: 0;
      align-items: center;
      border-radius: 0.25rem;
      .form-control {
        border: 0 !important;
        box-shadow: none !important;
        outline: none !important;
        height: 100%;
        padding: 0 1rem !important;
        height: 40px !important;
        z-index: 10 !important;
      }
      .btn {
        width: 32px;
        height: 40px;
        background: var(--bs-white);
      }
    }
    .btn-close-search {
      display: none;
      padding-left: 1rem;
      i {
        color: var(--bs-gray-500);
      }
    }
  }
  .map-box {
    background: var(--bs-gray-500);
    color: var(--bs-white);
    font-size: var(--fs-32);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    // 접속 AP
    .map-ap-badge {
      display: inline-block;
      font-size: var(--fs-14);
      font-weight: 700;
      color: var(--bs-danger);
      border: 2px solid var(--bs-danger);
      background: var(--bs-white) url('../images/ic_wifi.png') no-repeat 0.75rem
        center;
      border-radius: 100px;
      padding: 0.5rem 1.5rem 0.5rem 3rem;
      min-width: 1px;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
    }
    .map-marker {
      background-color: var(--bs-white);
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      border: 1px solid var(--bs-dark);
      position: relative;
      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid var(--bs-dark);
        content: '';
        position: absolute;
        bottom: -6px;
        left: 16px;
      }
      &.event {
        background-image: url('../images/ic_event.png');
        background-size: 19px auto;
      }
      &.tour {
        background-image: url('../images/ic_tour.png');
        background-size: 16px auto;
      }
      &.food {
        background-image: url('../images/ic_food_2.png');
        background-size: 24px auto;
      }
      &.cafe {
        background-image: url('../images/ic_local_cafe.png');
        background-size: 24px auto;
      }
      &.mart {
        background-image: url('../images/ic_storefront.png');
        background-size: 24px auto;
      }
      &.convenience {
        background-image: url('../images/ic_local_convenience_store.png');
        background-size: 24px auto;
      }
      &.stay {
        background-image: url('../images/ic_hotel.png');
        background-size: 24px auto;
      }
      &.beauty {
        background-image: url('../images/ic_cut.png');
        background-size: 24px auto;
      }
      &.company {
        background-image: url('../images/ic_factory.png');
        background-size: 24px auto;
      }
      &.etc {
        background-image: url('../images/ic_etc.png');
        background-size: 15px auto;
      }
      &:focus,
      &:active {
        background-color: var(--bs-dark);
        &.event {
          background-image: url('../images/ic_event_w.png');
        }
        &.tour {
          background-image: url('../images/ic_tour_w.png');
        }
        &.food {
          background-image: url('../images/ic_food_2_w.png');
          background-size: 24px auto;
        }
        &.cafe {
          background-image: url('../images/ic_local_cafe_w.png');
          background-size: 24px auto;
        }
        &.mart {
          background-image: url('../images/ic_storefront_w.png');
          background-size: 24px auto;
        }
        &.convenience {
          background-image: url('../images/ic_local_convenience_store_w.png');
          background-size: 24px auto;
        }
        &.stay {
          background-image: url('../images/ic_hotel_w.png');
          background-size: 24px auto;
        }
        &.beauty {
          background-image: url('../images/ic_cut_w.png');
          background-size: 24px auto;
        }
        &.company {
          background-image: url('../images/ic_factory_w.png');
          background-size: 24px auto;
        }
        &.etc {
          background-image: url('../images/ic_etc_w.png');
        }
      }
    }
  }
  .search-result {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background: var(--bs-white);
    overflow-y: auto;
    transform: translate(0, 20px);
    opacity: 0;
    visibility: hidden;
    transition: 0.15s ease-in-out;
    z-index: 10;
    &.show {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }
    .prm-list-item {
      box-shadow: none;
      border-top: 1px solid var(--bs-gray-200);
      &:first-of-type {
        border-top: 0;
      }
    }
  }
}
.prm-list-item {
  background: var(--bs-white);
  box-shadow: 0px -0.5px 4px rgba(104, 104, 104, 0.25);
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  .ratio {
    flex: 0 0 45%;
    margin-right: 1rem;
    border-radius: 0.25rem;
  }
  .flex-between {
    flex-direction: column;
    align-items: stretch;
    min-width: 1px;
    div {
      h5 {
        font-size: var(--fs-18);
        margin-bottom: 0.25rem;
        line-height: 1.25;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        overflow: hidden;
      }
      p {
        font-size: var(--fs-14);
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    small {
      font-size: var(--fs-14);
      color: var(--bs-gray);
    }
  }
}
#modal-prm-detail {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  position: fixed;
  left: 0;
  bottom: 80px;
  transform: translate(0, -20px);
  .modal-content {
    background-color: var(--bs-ts);
  }
}

// QR코드
#qrcode {
  // background: var(--bs-gray-500);
  background: #5e5e5e;
  color: var(--bs-white);
  font-size: var(--fs-32);
  // align-items: center;
  // justify-content: center;
  // text-align: center;
}

// QR코드 화면 준비중 css
.no-qr {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-qr small {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

#errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bs-gray-100);
}

.coupon-card {
  border: 4px solid #ff7f50;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  background-color: #f6ddd9;
  width: 80%;
  max-width: 400px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
}

.card-text {
  font-size: 18px;
  margin-bottom: 10px;
}

.coupon-code {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.btn {
  border-radius: 25px;
}

.container_cp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 768px) {
  .container_cp {
    height: auto;
    padding: 20px;
  }
}
