.modal {
  &.add-scroll-area {
    margin-left: -8px;
  }
  &.show {
    .modal-dialog {
      transform: none !important;
    }
  }
  .modal-dialog {
    margin: 3rem auto;
    max-width: 90%;
    &.modal-dialog-centered {
      min-height: calc(100% - 6rem);
      height: calc(100% - 6rem);
      max-width: 1024px;
    }
    &.modal-sm {
      max-width: 320px;
    }
    &.modal-fullscreen {
      border-radius: 0;
      margin: 0;
      max-width: 100%;
      transform: none;
    }
    .modal-content {
      border: 0;
      border-radius: 0.25rem;
      .modal-header {
        border: 0;
        padding: 1rem 1rem 0;
        .modal-title {
          font-weight: 700;
          font-size: var(--fs-18);
        }
        .btn-close {
          margin: 0 -0.75rem 0 auto;
          background-size: 0.85rem auto;
          padding: 0.75rem 1rem;
          opacity: 1 !important;
        }
      }
      .modal-body {
        padding: 1.5rem 1rem;
        font-weight: 500;
        [data-simplebar] {
          width: 100%;
        }
      }
      .modal-footer {
        padding-top: 0;
        border: 0;
        justify-content: center;
        .btn-lg {
          flex: 1;
        }
      }
    }
  }
}
