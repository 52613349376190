// reset.scss
@import url('//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

// BootStrap Root Reset
$theme-colors: (
  'primary': #ffecba,
  'primary-dark': #ecd69d,
  'primary-light': #fffde9,
  'secondary': #999,
  'success': #198754,
  'info': #0dcaf0,
  'warning': #ffc107,
  'danger': #dc3545,
  'light': #f8f9fa,
  'dark': #222,
  'gray': #4e4e4e,
  'gray-dark': #343a40,

  'border': #5b5b5b,
  'ts': transparent,

  'blue': #0d6efd,
  'indigo': #6610f2,
  'purple': #6f42c1,
  'pink': #d63384,
  'red': #dc3545,
  'orange': #fd7e14,
  'yellow': #ffc107,
  'green': #198754,
  'teal': #20c997,
  'cyan': #0dcaf0,

  'black': #000,
  'white': #fff,
  'gray-100': #f8f9fa,
  'gray-200': #e9ecef,
  'gray-300': #dee2e6,
  'gray-400': #ced4da,
  'gray-500': #adb5bd,
  'gray-600': #6c757d,
  'gray-700': #495057,
  'gray-800': #343a40,
  'gray-900': #212529,
);
$font-family-sans-serif: 'Montserrat', 'Noto Sans KR', sans-serif;

// add
:root {
  --fs-10: 0.625rem;
  --fs-11: 0.6875rem;
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
  --fs-28: 1.75rem;
  --fs-32: 2rem;
  --fs-48: 3rem;
  --fs-64: 4rem;
}

[class*=' xi-'],
[class^='xi-'] {
  font-size: var(--fs-24);
}
